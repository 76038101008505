<template>
  <div class="Polaris-Page">
    <div class="Polaris-Page__Content">
      <PolarisMessage title="Important!" :message="message">
        <div v-if="!setSeller">

          <button type="button" class="Polaris-Button Polaris-Button--primary " @click="fnShopifyLogout('/shopify')"
            :class="loading ? 'Polaris-Button--disabled Polaris-Button--loading':'' " :disabled="loading">
            <span class="Polaris-Button__Content">
              <span class="Polaris-Button__Spinner" v-show="loading ">
                <span class="Polaris-Spinner Polaris-Spinner--colorInkLightest Polaris-Spinner--sizeSmall"><svg
                    viewBox="0 0 20 20">
                    <path
                      d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z">
                    </path>
                  </svg>
                </span>
                <span role="status">
                  <span class="Polaris-VisuallyHidden" v-text=" 'Go back' "></span>
                </span>
              </span>
              <span class="Polaris-Button__Text" v-text=" 'Go back' ">
              </span>
            </span>
          </button>
        </div>
        <div v-else>
          <div class="Polaris-ButtonGroup">

            <div class="Polaris-ButtonGroup__Item">
              <button type="button" class="Polaris-Button " @click="fnShopifyLogout('/shopify')"
                :class="loading ? 'Polaris-Button--disabled Polaris-Button--loading':'' " :disabled="loading">
                <span class="Polaris-Button__Content">
                  <span class="Polaris-Button__Spinner" v-show="loading ">
                    <span class="Polaris-Spinner Polaris-Spinner--colorInkLightest Polaris-Spinner--sizeSmall"><svg
                        viewBox="0 0 20 20">
                        <path
                          d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z">
                        </path>
                      </svg>
                    </span>
                    <span role="status">
                      <span class="Polaris-VisuallyHidden" v-text=" 'Register with another' "></span>
                    </span>
                  </span>
                  <span class="Polaris-Button__Text" v-text=" 'Register with another' ">
                  </span>
                </span>
              </button>
            </div>

            <div class="Polaris-ButtonGroup__Item">
              <button type="button" class="Polaris-Button Polaris-Button--primary " @click="fnApiUpdateSellerStore()"
                :class="loading ? 'Polaris-Button--disabled Polaris-Button--loading':'' " :disabled="loading">
                <span class="Polaris-Button__Content">
                  <span class="Polaris-Button__Spinner" v-show="loading ">
                    <span class="Polaris-Spinner Polaris-Spinner--colorInkLightest Polaris-Spinner--sizeSmall"><svg
                        viewBox="0 0 20 20">
                        <path
                          d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z">
                        </path>
                      </svg>
                    </span>
                    <span role="status">
                      <span class="Polaris-VisuallyHidden" v-text=" 'Register with this account' "></span>
                    </span>
                  </span>
                  <span class="Polaris-Button__Text" v-text=" 'Register with this account' ">
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </PolarisMessage>
    </div>
  </div>
</template>

<script>
  import '@shopify/polaris/styles.css';
  import {
    mapState,
    mapActions,
  } from 'vuex';
  export default {
    components: {
      PolarisMessage: () => import('@/components/shopify/polaris-message'),
    },
    data() {
      return {
        message: 'Sorry the store no longer exists, please delete the application and install again.',
        setSeller: false,
        loading: false,
      }
    },
    computed: {
      ...mapState('ShopifySession', ['user']),
    },
    methods: {
      ...mapActions('ShopifySession', ['fnShopifyLogout']),
      fnGoBack() {
        window.history.back();
      },
      async fnApiUpdateSellerStore() {
        this.loading = true;
        await axiosShopify.put(`stores/${this.$route.query.store_id}/seller`).then(async (response) => {
          this.$router.push({
            path: '/shopify/account',
          })
        }).catch(error => {});
        this.loading = false;
      }
    },
    mounted() {
      if (this.$route.query.message) {
        this.message = this.$route.query.message;
      }
      if (this.$route.query.set_seller) {
        this.setSeller = this.$route.query.set_seller;
      }
    }
  }
</script>

<style>

</style>